<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card id="loginCard" class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <v-img
              :src="require('@/assets/images/logos/templogo.png')"
              max-height="120px"
              max-width="200px"
              alt="logo"
              contain
              class="me-3"
            ></v-img>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">Welcome to Multistreamer! 👋🏻</p>
          <p class="mb-2">Please sign-in to your account.</p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form>
            <v-text-field
              v-model="user"
              outlined
              label="User Name"
              placeholder="User Name"
              hide-details
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="pass"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <!-- <div class="d-flex align-center justify-space-between flex-wrap">
              <v-checkbox
                label="Remember Me"
                hide-details
                class="me-3 mt-1"
              >
              </v-checkbox>

              <a
                href="javascript:void(0)"
                class="mt-1"
              >
                Forgot Password?
              </a>
            </div> -->

            <v-btn block color="primary" class="mt-6" @click="login"> Login </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
      <v-snackbar v-model="snackbar" :timeout="timeout" centered rounded="pill" height="25%" width="45%" color="error">
        <div class="text-h5">
          {{ text }}
        </div>
        <template v-slot:action="{ attrs }">
          <v-btn color="primary" class="mr-4" v-bind="attrs" @click="snackbar = false"> Close </v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  setup() {
    const isPasswordVisible = ref(false)
    const user = ref('')
    const pass = ref('')

    return {
      isPasswordVisible,
      user,
      pass,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      snackbar: false,
      text: 'Wrong Username Or Password!',
      timeout: 3000,
    }
  },
  mounted() {
    // After the page load up, check for local storage for log in session to redirect to home page
    if (localStorage && localStorage.getItem('logged')) {
      this.$http
        .get('/api/info')
        .then(response => {
          if (response) {
            // console.log(response.data)
            this.$router.push({ name: 'home', path: '/home' })
          }
        })
        .catch(err => console.log(err))
    }
  },
  methods: {
    // send user&pass for backend then enter the app with storing local session or get 404.
    login() {
      this.$http
        .post('api/login', { username: this.user, password: this.pass })
        .then(res => {
          if (res) {
            // console.log(res)
            localStorage.setItem('logged', 1)
            this.$router.push({ name: 'home', path: '/home' })
          }
        })
        .catch(err => {
          if (err) {
            setTimeout(() => {
              document.getElementById('loginCard').classList.add('shakeNow')
            }, 100)
            this.snackbar = true
            setTimeout(() => {
              document.getElementById('loginCard').classList.remove('shakeNow')
            }, 2000)

            // console.log(err)
            // this.$router.push({ name: 'error-404', path: '/error-404' })
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
.shakeNow {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
  display: inline-block;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
